import React from "react";
import { styled } from "../../../stitches.config";
import { ListCheckbox } from "../shared/ListCheckbox";

type Props = {
  content: string;
};

const SelectListStyle: React.FC<{
  style: string;
  items: string[];
}> = ({ style, items }) => {
  switch (style) {
    case "ordered":
      return (
        <>
          {items.map((el, i) => (
            <ListBox key={i}>
              <List
                dangerouslySetInnerHTML={{ __html: `${i + 1}. ${el}` }}
              ></List>
            </ListBox>
          ))}
        </>
      );
    case "unordered":
      return (
        <TaskBox>
          {items.map((item, i) => (
            <TaskItem key={i}>
              <span dangerouslySetInnerHTML={{ __html: `${item}` }} />
            </TaskItem>
          ))}
        </TaskBox>
      );

    default:
      return null;
  }
};

const FillTable: React.FC<{ table: any }> = ({ table }) => {
  const content = table.withHeadings ? table.content.slice(1) : table.content;
  return (
    <TableContainer>
      <Table>
        {table.withHeadings && (
          <thead>
            <tr>
              {table.content[0].map((element: any, index: number) => (
                <th key={index}>
                  <TableText
                    css={{ fontWeight: 700 }}
                    dangerouslySetInnerHTML={{ __html: `${element}` }}
                  ></TableText>
                </th>
              ))}
            </tr>
          </thead>
        )}
        {content.map((el: any, i: number) => {
          return (
            <tr key={i}>
              {el.map((element: any, index: number) => (
                <Td key={index}>
                  <TableText
                    dangerouslySetInnerHTML={{ __html: `${element}` }}
                  ></TableText>
                </Td>
              ))}
            </tr>
          );
        })}
      </Table>
    </TableContainer>
  );
};

const WarningRenderer: React.FC<{ warning: any }> = ({ warning }) => {
  return (
    <WarningBox>
      <WarningTitle>{warning.title}</WarningTitle>
      <WarningText>{warning.message}</WarningText>
    </WarningBox>
  );
};

const ImagesRender: React.FC<{ images: any }> = ({ images }) => {
  return (
    <ImageContainer>
      <ArticleImage
        css={{ margin: "0 auto !important" }}
        src={images.file.url}
        alt="article image"
      />
    </ImageContainer>
  );
};

const SelectHeaderLevel: React.FC<{ size: number; text: string }> = ({
  size,
  text,
}) => {
  switch (size) {
    case 1:
      return <Title dangerouslySetInnerHTML={{ __html: text }} />;
    case 2:
      return <Subtitle dangerouslySetInnerHTML={{ __html: text }} />;
    case 3:
      return <H3 dangerouslySetInnerHTML={{ __html: text }} />;
    case 4:
      return <H4 dangerouslySetInnerHTML={{ __html: text }} />;
    default:
      return null;
  }
};

const CheckListRenderer: React.FC<{ checklistItems: any }> = ({
  checklistItems,
}) => {
  return (
    <CheckBoxList>
      {checklistItems.items.map((el: any, i: number) => (
        <ListCheckbox key={i} label={el.text} checked={el.checked} />
      ))}
    </CheckBoxList>
  );
};

const Builder: React.FC<{ element: any }> = ({ element }) => {
  switch (element.type) {
    case "paragraph":
      return (
        <Text dangerouslySetInnerHTML={{ __html: `${element.data.text}` }} />
      );
    case "header":
      return (
        <SelectHeaderLevel size={element.data.level} text={element.data.text} />
      );
    case "list":
      return (
        <SelectListStyle
          style={element.data.style}
          items={element.data.items}
        />
      );
    case "image":
      return <ImagesRender images={element.data} />;
    case "delimiter":
      return <Delimiter>* * *</Delimiter>;
    case "checklist":
      return <CheckListRenderer checklistItems={element.data} />;
    case "warning":
      return <WarningRenderer warning={element.data} />;
    case "table":
      return <FillTable table={element.data} />;
    case "quote":
      return (
        <Quote>
          <QuoteText>"{element.data.text}"</QuoteText>
          <QuoteAuthor>
            - {element.data.caption || "Unknown author"}.
          </QuoteAuthor>
        </Quote>
      );
    case "LinkTool":
      return (
        <Link href={element.data.link} target="_blank">
          {element.data.meta.title || element.data.link}
        </Link>
      );
    default:
      return null;
  }
};

export const VacancyRichTextParser: React.FC<Props> = ({ content }) => {
  const richTextObj = eval("({obj:[" + content + "]})");

  return (
    <Container>
      {richTextObj.obj[0].blocks.map((el: any, i: number) => (
        <Builder key={i} element={el} />
      ))}
    </Container>
  );
};

const TableContainer = styled("div", {
  width: "100%",
  overflow: "auto",
  padding: 5,
});

const Table = styled("table", {
  borderCollapse: "collapse",
  margin: "18px auto",
  borderRadius: "8px",
  borderStyle: "hidden",
  overflow: "hidden",
  boxShadow: "0 0 0 1px #9F9F9F",
  th: {
    border: "1px solid #9F9F9F",
    backgroundColor: "$primaryBackground",
    padding: "8px",
    textAlign: "left",
  },
});

const Td = styled("td", {
  verticalAlign: "top",
  border: "1px solid #9F9F9F",
  padding: "8px",
  maxWidth: 200,
});

const TableText = styled("p", {
  fontWeight: "400",
  fontSize: "18px",
  margin: 0,
  lineHeight: 1.5,
});

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  flexWrap: "wrap",
  width: "100%",
  padding: "0px",
  "@md": {
    padding: "0px",
  },
});

const WarningBox = styled("div", {
  margin: "18px auto",
  border: "1px solid $accent",
  borderRadius: "10px",

  padding: "12px",
  backgroundColor: "$secondaryBackground",
  width: "fit-content",
  "@md": {
    padding: "12px",
  },
});

const WarningTitle = styled("h3", {
  color: "$warning",
  margin: "0 0 8px",
  fontWeight: 700,
  paddingBottom: 4,
  borderBottom: "1px solid $accent",
});

const WarningText = styled("p", {
  margin: 0,
  color: "$warning",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
});

const CheckBoxList = styled("div", {
  margin: "30px 0",
});

const CheckBoxItem = styled("div", {
  display: "flex",
  gap: 10,
  alignItems: "flex-start",
  "@md": {
    gap: 20,
  },
});

const CheckInput = styled("input", {
  width: 25,
  height: 25,
  margin: 0,
});

const CheckText = styled("p", {
  margin: "0 0 20px",
});

const Quote = styled("div", {
  borderLeft: "5px solid $accent",
  padding: "8px",
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
  backgroundColor: "$secondaryBackground",
  width: "fit-content",
  marginLeft: "auto",
  minWidth: 240,
});

const QuoteText = styled("p", {
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  margin: "0 0 10px",
});

const QuoteAuthor = styled("p", {
  fontWeight: "600",
  fontSize: "18px",
  lineHeight: "24px",

  margin: "0px",
  textAlign: "end",
});

const Title = styled("h1", {
  fontWeight: "600",
  fontSize: "30px",
  lineHeight: "32px",
  margin: "0 auto",
  "@md": {
    fontSize: "40px",
    lineHeight: "50px",
  },
});

const Subtitle = styled("h2", {
  fontWeight: "600",
  fontSize: "26px",
  lineHeight: "30px",
  margin: "0 0 30px",
  "@md": {
    fontSize: "34px",
    lineHeight: "40px",
  },
});

const H3 = styled("h3", {
  fontWeight: "600",
  fontSize: "22px",
  lineHeight: "28px",
  margin: "0 0 30px",
  "@md": {
    fontSize: "30px",
    lineHeight: "36px",
  },
});

const H4 = styled("h4", {
  fontWeight: "600",
  fontSize: "20px",
  lineHeight: "26px",
  margin: "0 0 30px",
  "@md": {
    fontSize: "26px",
    lineHeight: "32px",
  },
});

const ListBox = styled("ul", {
  display: "flex",
  alignItems: "center",
  listStyle: "none",
  gap: 10,
  margin: "0 0 10px",
  "@md": {
    gap: 20,
    margin: "0 0 20px",
  },
});

const List = styled("li", {
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "30px",
  margin: 0,
});

const Text = styled("p", {
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "30px",
  margin: "5px 0px",
});

const Delimiter = styled("p", {
  fontWeight: "500",
  fontSize: "30px",
  lineHeight: "30px",
  margin: "30px auto",
  textAlign: "center",
});

const Link = styled("a", {
  display: "block",
  fontWeight: "600",
  fontSize: "18px",
  lineHeight: "30px",
  margin: "0 0 20px",
  color: "$accent",
  textDecoration: "none",
  cursor: "pointer",
  "&:hover": {
    opacity: 0.9,
  },
});

const ArticleImage = styled("img", {
  objectFit: "contain",
  borderRadius: "8px",
  display: "block",
  maxWidth: "100%",
  maxHeight: "400px",
  "@md": {
    maxHeight: "500px",
  },
});

const ImageContainer = styled("div", {
  height: "100%",
  margin: "12px 0px",
  "@md": {
    height: "500px",
  },
});

const TaskBox = styled("ul", {
  display: "flex",
  listStyle: "none",
  flexDirection: "row",
  gap: 12,
  justifyContent: "space-between",
  flexWrap: "wrap",
  maxWidth: 720,
  padding: 0,
});

const TaskItem = styled("li", {
  position: "relative",
  color: "$text",
  margin: "0 0 0px 30px",
  "&:before": {
    content: "",
    position: "absolute",
    width: 12,
    height: 12,
    top: 4,
    left: -30,
    borderRadius: "100%",
    background: "$accent",
  },
});
