import React from "react";
import { styled } from "../../../stitches.config";
import { Layout } from "../../layout";
import { Colors, Hero } from "../../components/shared/Hero";
import { Button } from "../../components/shared/Button";
import MainImage from "../../images/career/vacancy.svg";
import Monitor from "../../images/career/monitor.svg";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import CityIcon from "../../images/career/city.svg";
import { Vacancy } from "../../types/vacancy";
import { routes } from "../../routes/routes";
import { Seo } from "../../components/shared/Seo";
import { VacancyRichTextParser } from "../../components/Career/RichTextParser";
import { useAppSelector } from "../../hooks/ReduxHooks";

type Props = {
  vacancy: Vacancy;
};

export const VacancyView: React.FC<Props> = ({ vacancy }) => {
  const { t } = useTranslation();
  const { navigate } = useI18next();
  const bgColor = Colors.transparent;
  const { primaryNumber } = useAppSelector((state) => state.contactInfo);

  return (
    <>
      <Layout>
        <Seo
          title={`${vacancy.attributes.title}, ${vacancy.attributes.city}`}
          description={vacancy.attributes.description}
          image={MainImage}
        />
        <Hero pic={{ wrap: Image, src: MainImage }} backgroundColor={bgColor}>
          <Heading>{vacancy?.attributes.title}</Heading>
          <Box>
            <Icon src={CityIcon} />
            <City>{vacancy.attributes.city}</City>
          </Box>
          <Description>{vacancy.attributes.description}</Description>
        </Hero>
        <Container>
          <Title>{t("vacancy.sub.first.title")}</Title>
          {vacancy.attributes.tasks && (
            <VacancyRichTextParser content={vacancy.attributes.tasks} />
          )}
          <Title css={{ marginTop: 40 }}>{t("vacancy.sub.second.title")}</Title>
          <InfoWrap>
            {vacancy?.attributes.requirements && (
              <VacancyRichTextParser
                content={vacancy.attributes.requirements}
              />
            )}
          </InfoWrap>
        </Container>
        <BenefitContainer>
          <BenefitWrapper>
            <Title>{t("vacancy.sub.third.title")}</Title>
            {vacancy?.attributes && (
              <VacancyRichTextParser content={vacancy.attributes.benefits} />
            )}
          </BenefitWrapper>
        </BenefitContainer>
        <InterestingBox>
          <div>
            <InterestingTitle>{t("vacancy.sub.senden.title")}</InterestingTitle>
            <InterestingText
              dangerouslySetInnerHTML={{
                __html: t("vacancy.sub.senden.text", {
                  primaryNumber,
                }),
              }}
            />
            <InterestingButtonsWrap>
              <Button
                onClick={() => navigate(routes.contact.employment)}
                colorScheme="support"
              >
                {t("vacancy.button.jetztBewerben")}
              </Button>
            </InterestingButtonsWrap>
          </div>
          <InterestingImage src={Monitor} />
        </InterestingBox>
      </Layout>
    </>
  );
};

const InterestingImage = styled("img", {
  display: "none",
  "@md": {
    display: "inherit",
    width: 150,
    marginRight: "100px",
  },
});

const InterestingButtonsWrap = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  margin: "0 auto",
  "@md": {
    flexDirection: "row",
  },
});

const InterestingTitle = styled("h2", {
  color: "$white",
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "28px",
  margin: "0 0 12px",
  "@md": {
    color: "$text",
    width: 566,
  },
});

const InterestingText = styled("p", {
  color: "$white",
  fontWeight: 600,
  fontSize: "17px",
  lineHeight: "24px",
  margin: "0 0 12px",
  a: {
    textDecoration: "none",
    color: "$accent",
    "&:hover": {
      opacity: 0.8,
    },
  },
  "@md": {
    width: 566,
    color: "$text",
    margin: "0 0 32px",
  },
});

const InterestingBox = styled("div", {
  display: "flex",
  flexDirection: "column",
  background: "$accent",
  padding: "60px 24px",
  "@md": {
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: "1280px",
    margin: "0 auto 120px",
    padding: "70px 92px",
    background: "$secondaryBackground",
    borderRadius: "8px",
  },
});

const BenefitContainer = styled("div", {
  background: "$primaryBackground",
  margin: "0",
  padding: "0 16px",
  "@md": {
    margin: "0 0 80px",
    padding: 0,
  },
});

const BenefitWrapper = styled("div", {
  maxWidth: "1280px",
  margin: "0 auto",
  padding: "32px 0",
  "@md": {
    padding: "40px 0",
  },
});

const InfoWrap = styled("div", {
  "@md": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 60,
  },
});

const Container = styled("div", {
  maxWidth: "1280px",
  margin: "20px auto",
  padding: "0 16px",
  "@md": {
    padding: "0",
    margin: "24px auto 40px auto",
  },
});

const Title = styled("h2", {
  margin: "0px auto 20px",
  fontWeight: 700,
  fontSize: "20px",
  lineHeight: "24px",
  "@md": {
    fontSize: "24px",
    lineHeight: "28px",
  },
});

const Image = styled("img", {
  objectFit: "contain",
  maxWidth: "494px",
  width: "90%",
});

const Heading = styled("h1", {
  color: "$heading",
  fontSize: "32px",
  fontWeight: 700,
  lineHeight: "40px",
  margin: "0 0 12px",

  variants: {
    color: {
      transparent: {
        color: "$text",
      },
    },
  },
  "@md": {
    margin: "32px 0 12px",
    fontSize: "48px",
    lineHeight: "56px",
    maxWidth: "602px",
  },
});

const City = styled("p", {
  margin: 0,
  fontWeight: 400,
  fontSize: "13px",
  lineHeight: "20px",
  color: "$screedDisplayText",
});

const Icon = styled("img", {
  margin: "2px 0",
});

const Box = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: 6,
  margin: "8px 0",
});

const Description = styled("p", {
  lineHeight: 1.5,
  margin: "0 0 22px 0",
});
