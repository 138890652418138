import React, { useEffect } from "react";
import { GetServerDataProps, graphql, PageProps } from "gatsby";
import { VacancyView } from "../../../views/VacancyView/VacancyView";
import { sendRequestToAPI } from "../../../api/graphqlRequest";
import { Vacancy } from "../../../types/vacancy";
import { useI18next } from "gatsby-plugin-react-i18next";

interface ContextParams {
  [key: string]: string;
}

export type VacancyServerData = {
  vacancy: Vacancy;
  notFound: boolean;
};

const VacancyPage: React.FC<PageProps> = ({ serverData }) => {
  const { navigate } = useI18next();
  const { vacancy, notFound } = serverData as VacancyServerData;

  useEffect(() => {
    notFound && navigate("/404", { replace: true });
  }, [notFound]);

  if (notFound) {
    return null;
  }
  return <VacancyView vacancy={vacancy} />;
};

export async function getServerData(context: GetServerDataProps) {
  const { id } = context.params as ContextParams;

  const getData = async () => {
    try {
      const { data } = await sendRequestToAPI(
        `
        query($slug: String){
          vacancies(filters:{slug:{eq: $slug}}){
          data{attributes{
            tasks
            benefits
            requirements
            city
            title
            description
            slug
            }}
           }
           }
      `,
        { slug: id },
        {},
        process.env.GATSBY_API_URL
      );
      return data;
    } catch (err) {
      return err;
    }
  };
  const { data } = await getData();
  const notFound = !data?.vacancies?.data.length;
  const pageData: VacancyServerData = {
    vacancy: data.vacancies.data[0],
    notFound,
  };

  return {
    props: { ...pageData },
  };
}

export default VacancyPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
