import React from "react";
import { styled } from "../../../stitches.config";

type Props = {
  checked: boolean;
  label: string;
};

export const ListCheckbox: React.FC<Props> = ({ label, checked }) => {
  return (
    <Checkbox>
      <Check checked={checked} />
      <Label>{label}</Label>
    </Checkbox>
  );
};

const Label = styled("span", {
  fontSize: "18px",
  lineHeight: 1.3,
});

const Check = styled("span", {
  border: "1px solid $accent",
  display: "inline-block",
  width: "18px",
  height: "18px",
  borderRadius: "2px",
  position: "relative",
  backgroundColor: "$white",
  flexShrink: 0,
  variants: {
    checked: {
      true: {
        backgroundColor: "$accent",
        "&:before": {
          content: "",
          width: "9px",
          height: "5px",
          position: "absolute",
          border: "solid $white",
          borderRadius: "1px",
          borderWidth: "0px 0px 3px 3px",
          left: "2px",
          bottom: "5px",
          transform: "rotate(-45deg)",
        },
      },
    },
  },
});

const Checkbox = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: 8,
});
